import React from "react";
var __jsx = React.createElement;
import { useSelector } from 'react-redux';
import { selectIsEmbedded } from '~/common/slices/app.slice';
import { RouteName } from '~/navigation/Routes';
import { HomeScreen } from '~/screens/home/HomeScreen';
import { ServiceSelectionScreen } from '~/screens/services/ServiceSelectionScreen';
function HomePage() {
  var isEmbedded = useSelector(selectIsEmbedded);

  // If we are at widget mode, HOME is the ServiceSelectionScreen
  if (isEmbedded) {
    return __jsx(ServiceSelectionScreen, null);
  }
  return __jsx(HomeScreen, null);
}
HomePage.displayName = RouteName.Home;
export default HomePage;