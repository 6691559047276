import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { branchCourses as branchCoursesResource } from '~/api/Resources';
import { TEN_MINS_IN_MILLISECONDS } from '~/api/models/Constants';
import { fetchResource } from '~/common/network/network';
import { selectBranchId } from '~/common/slices/app.slice';
import { selectBranch } from '~/common/slices/model.slice';
export var useCoursePurchase = function useCoursePurchase(props) {
  var _branch$isCoursePurch, _branchCourses$branch2;
  var _ref = props !== null && props !== void 0 ? props : {},
    categoryId = _ref.categoryId;
  var branchId = useSelector(selectBranchId);
  var branch = useSelector(selectBranch);
  var _useQuery = useQuery([branchCoursesResource.path, branchId], function () {
      return fetchResource(branchCoursesResource);
    }, {
      cacheTime: TEN_MINS_IN_MILLISECONDS,
      enabled: (_branch$isCoursePurch = branch === null || branch === void 0 ? void 0 : branch.isCoursePurchasesEnabled) !== null && _branch$isCoursePurch !== void 0 ? _branch$isCoursePurch : false
    }),
    branchCourses = _useQuery.data;
  var purchasableCategories = useMemo(function () {
    var _branchCourses$branch;
    if (!branchCourses) {
      return [];
    }
    var idSet = new Set();
    return (_branchCourses$branch = branchCourses === null || branchCourses === void 0 ? void 0 : branchCourses.branchCourses.map(function (course) {
      var _course$category;
      if (!idSet.has((_course$category = course.category) === null || _course$category === void 0 ? void 0 : _course$category.id)) {
        var _course$category2;
        idSet.add((_course$category2 = course.category) === null || _course$category2 === void 0 ? void 0 : _course$category2.id);
        return course.category;
      } else {
        return null;
      }
    }).filter(Boolean)) !== null && _branchCourses$branch !== void 0 ? _branchCourses$branch : [];
  }, [branchCourses]);
  return {
    purchasableCourses: (_branchCourses$branch2 = branchCourses === null || branchCourses === void 0 ? void 0 : branchCourses.branchCourses.filter(function (course) {
      var _course$category3;
      return !categoryId || ((_course$category3 = course.category) === null || _course$category3 === void 0 ? void 0 : _course$category3.id) === categoryId;
    })) !== null && _branchCourses$branch2 !== void 0 ? _branchCourses$branch2 : [],
    purchasableCategories: purchasableCategories
  };
};