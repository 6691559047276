import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { useSelector } from 'react-redux';
import Localize from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import { selectIsUserLoggedIn } from '~/common/slices/user.slice';
import LoadingView from '~/components/LoadingView';
import { buildAppointmentViewModel } from '~/screens/appointments/AppointmentHistoryViewModel';
import PastAppointment from '~/screens/appointments/components/PastAppointment';
import useAppointmentHistory from '~/screens/appointments/useAppointmentHistory';
import { Container, Theme, themed, Title2 } from '~/styles/Theme';
import { findLastNonCancelledPreviousAppointment } from '../HomeViewModel';
var PastAppointmentBanner = function PastAppointmentBanner(props) {
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var _useAppointmentHistor = useAppointmentHistory(),
    isLoading = _useAppointmentHistor.isLoading,
    isError = _useAppointmentHistor.isError,
    pastAppointments = _useAppointmentHistor.pastAppointments,
    onRebookPress = _useAppointmentHistor.onRebookPress;
  var onAppointmentSelection = function onAppointmentSelection(appointment) {
    onRebookPress(appointment, AnalyticsEvents.RebookFromHomeStart);
  };
  if (!isUserLoggedIn) return null;
  if (isLoading) {
    return __jsx(LoadingView, null);
  }
  if (isError) {
    return null;
  }
  if (pastAppointments && pastAppointments.length > 0) {
    var viewModel = buildAppointmentViewModel(pastAppointments);
    if (viewModel && viewModel.length > 0) {
      var appointment = findLastNonCancelledPreviousAppointment(viewModel);
      if (appointment) {
        return __jsx(AppointmentContainer, null, __jsx(TitleText, null, Localize('home.lastAppointment')), __jsx(PastAppointment, {
          key: appointment.id,
          appointment: appointment,
          onPress: function onPress() {
            return onAppointmentSelection(appointment);
          },
          homePage: props.homePage
        }));
      }
    }
    return null;
  }
  return null;
};
var AppointmentContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 30px;\n"])));
var TitleText = themed(Title2)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.title);
});
export default PastAppointmentBanner;