import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { useSelector } from 'react-redux';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import { FeatureFlagsEnum, selectBranch, selectBusiness, selectFeatureFlag } from '~/common/slices/model.slice';
import { themed, Container, Theme } from '~/styles/Theme';
import { EmailAddress, OpeningTimes, PhoneNumber, SalonAddress } from '../HomeComponents';
function ContactInfo() {
  var _business$locale;
  var business = useSelector(selectBusiness);
  var branch = useSelector(selectBranch);
  var country = (business === null || business === void 0 || (_business$locale = business.locale) === null || _business$locale === void 0 ? void 0 : _business$locale.country) || 'IE';
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  var showOpeningHoursFeatureFlag = useSelector(selectFeatureFlag(FeatureFlagsEnum.OpeningHours));
  if (!branch) {
    return null;
  }
  return __jsx(ContactContainer, {
    isMobile: isMobile,
    testID: "salonContactInfo"
  }, __jsx(VerticalContainer, null, __jsx(SalonAddress, {
    branch: branch
  }), branch.phone ? __jsx(PhoneNumber, {
    phone: branch.phone,
    country: country
  }) : null, branch.email ? __jsx(EmailAddress, {
    email: branch.email
  }) : null), showOpeningHoursFeatureFlag ? __jsx(VerticalContainer, null, __jsx(OpeningTimes, {
    branch: branch
  })) : null);
}
export default ContactInfo;
var ContactContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-grow: 1\n  flex-shrink: 1\n  flex-basis: 0%\n  flex-direction: row\n  ", "\n  padding: 8px 24px 16px 8px\n  elevation: 1\n  shadow-color: gray\n  shadow-offset: 2px 2px\n  shadow-opacity: 0.2\n  shadow-radius: 4px\n  border-width: 2px\n  border-color: transparent\n  border-radius: 8px\n  ", "\n"])), function (_ref) {
  var isMobile = _ref.isMobile;
  if (isMobile) {
    return "\n        flex-direction: column\n      ";
  } else {
    return "\n        flex-direction: row\n        align-items: flex-start\n      ";
  }
}, function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var VerticalContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-grow: 1\n  flex-shrink: 1\n  flex-basis: 0%\n  justify-content: center;\n  backgroundColor: white\n  borderRadius: 8px\n  margin: 6px 0px\n  padding: 0px 20px 0px 20px\n"])));