import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import Icon from 'react-native-vector-icons/Feather';
import { useRouter } from 'solito/router';
import Localize from '~/common/Localize';
import ListItem from '~/components/ListItem';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { Container, Theme, themed, Title2 } from '~/styles/Theme';
export default function ConsultationsBanner() {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  function handlePress() {
    push(getRouteUrl(RouteName.ConsultationsForm));
  }
  return __jsx(MainContainer, {
    testID: "consultationsBanner"
  }, __jsx(TitleText, null, Localize('leads.sections.contactUs')), __jsx(ListItem, {
    title: Localize('leads.sections.title'),
    subtitle: Localize('leads.sections.subtitle'),
    onPress: handlePress,
    border: true,
    homePage: true,
    image: __jsx(PhoneIcon, {
      name: "phone",
      size: 25
    }),
    testID: "consultationsListItem"
  }));
}
var MainContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 20px;\n"])));
var TitleText = themed(Title2)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.title);
});
var PhoneIcon = themed(Icon)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.icon);
});