import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMemo } from 'react';
import { Image, View, useWindowDimensions } from 'react-native';
import { valueForPlatform } from '~/common/Helpers';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import QuickImage from '~/components/QuickImage';
import { Container, themed, useAppTheme } from '~/styles/Theme';
import { NotificationBanner } from '../HomeComponents';
var MAX_HERO_HEIGHT = 390;
var SalonHero = function SalonHero(props) {
  var _useWindowDimensions = useWindowDimensions(),
    width = _useWindowDimensions.width;
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  var theme = useAppTheme();
  var heroHeight = useMemo(function () {
    return Math.min(width, MAX_HERO_HEIGHT);
  }, [width]);
  var image;
  if (props.url) {
    image = __jsx(QuickImage, {
      style: _objectSpread({
        width: width,
        height: heroHeight
      }, props.style),
      source: {
        uri: props.url
      }
    });
  } else {
    image = __jsx(Image, {
      source: {
        uri: 'hero'
      },
      style: _objectSpread({
        width: width,
        height: width
      }, props.style)
    });
  }
  return valueForPlatform({
    web: isMobile && props.url ? __jsx(View, {
      testID: "webMobileHero"
    }, image, props.branch ? __jsx(ContentContainer, null, __jsx(NotificationBanner, {
      branch: props.branch
    })) : null) : __jsx(View, {
      style: {
        backgroundColor: theme.colors.main,
        height: 200
      },
      testID: "webDesktopHero"
    }, props.branch ? __jsx(WebContentContainer, null, __jsx(NotificationBanner, {
      branch: props.branch
    })) : null),
    "native": __jsx(View, {
      testID: "nativeHero"
    }, image, props.branch ? __jsx(MessageContainer, null, __jsx(NotificationBanner, {
      branch: props.branch
    })) : null)
  });
};
var MessageContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  bottom: 40px\n  position: absolute\n  left: 20px\n  right: 20px\n  width: 90%\n"])));
var ContentContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 1024px\n  width: 90%\n  border-radius: 8px\n  height: 70px\n  position: absolute\n  bottom: 40px\n  left: 20px\n  right: 20px\n"])));
var WebContentContainer = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: 1024px\n  width: 90%\n  margin: 40px auto 16px\n  border-radius: 8px\n  height: 70px\n"])));
export default SalonHero;