import React from "react";
var __jsx = React.createElement;
import { useSelector } from 'react-redux';
import useSalonDisplayName from '~/common/hooks/useSalonDisplayName';
import { selectEnv } from '~/common/slices/app.slice';
import { FeatureFlagsEnum, selectBranch, selectBrand, selectBusiness, selectFeatureFlag } from '~/common/slices/model.slice';
import LoadingView from '~/components/LoadingView';
import { RouteName } from '~/navigation/Routes';
import { ThemeName, useAppTheme } from '~/styles/Theme';
import HomeScreenFlora from './HomeScreenFlora';
import HomeScreenNeonNights from './HomeScreenNeonNights';
export function HomeScreen() {
  var _business$locale;
  var theme = useAppTheme();
  var env = useSelector(selectEnv);
  var business = useSelector(selectBusiness);
  var branch = useSelector(selectBranch);
  var salonDisplayName = useSalonDisplayName();
  var country = (business === null || business === void 0 || (_business$locale = business.locale) === null || _business$locale === void 0 ? void 0 : _business$locale.country) || 'IE';
  var shouldHideOnlineStore = useSelector(selectFeatureFlag(FeatureFlagsEnum.HideOnlineStore));

  // TODO: figure out a way to understand when is ready vs brand only
  var brand = useSelector(selectBrand);
  if (!brand || !salonDisplayName) {
    return __jsx(LoadingView, null);
  }
  return theme.name === ThemeName.NeonNights ? __jsx(HomeScreenNeonNights, {
    business: business,
    branch: branch,
    displayName: salonDisplayName,
    country: country,
    env: env,
    shouldHideOnlineStore: shouldHideOnlineStore
  }) : __jsx(HomeScreenFlora, {
    business: business,
    branch: branch,
    displayName: salonDisplayName,
    country: country,
    env: env,
    shouldHideOnlineStore: shouldHideOnlineStore
  });
}
HomeScreen.displayName = RouteName.Home;
export default HomeScreen;