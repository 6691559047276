import React from "react";
var __jsx = React.createElement;
import { Image, useWindowDimensions, View } from 'react-native';

// Todo Fix with Dimensions
var LightInBox = function LightInBox() {
  var _useWindowDimensions = useWindowDimensions(),
    width = _useWindowDimensions.width,
    height = _useWindowDimensions.height;
  return __jsx(View, {
    style: {
      height: height,
      width: width,
      position: 'absolute',
      alignItems: 'center'
    }
  }, __jsx(Image, {
    style: {
      position: 'absolute',
      bottom: 40,
      width: '20%',
      height: '20%',
      resizeMode: 'contain'
    },
    source: require('../../../../resources/img/Egg.png')
  }));
};
export default LightInBox;