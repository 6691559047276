import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3;
import React from "react";
var __jsx = React.createElement;
import { useQuery } from '@tanstack/react-query';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { serviceGroups } from '~/api/Resources';
import { hasBookableSpecialOffers } from '~/api/models/ModelFunctions';
import Localize from '~/common/Localize';
import { fetchResource } from '~/common/network/network';
import { selectBranchId } from '~/common/slices/app.slice';
import ListItem from '~/components/ListItem';
import LoadingView from '~/components/LoadingView';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { Container, Theme, themed, Title2 } from '~/styles/Theme';
var SpecialOffersBanner = function SpecialOffersBanner() {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var branchId = useSelector(selectBranchId);
  var _useQuery = useQuery([serviceGroups.path, branchId], function () {
      return fetchResource(serviceGroups);
    }),
    isLoading = _useQuery.isLoading,
    error = _useQuery.error,
    data = _useQuery.data;
  function handlePress() {
    push(getRouteUrl(RouteName.ServiceSelection, {
      showSpecialOffers: true
    }));
  }
  if (isLoading) {
    return __jsx(MainContainer, null, __jsx(LoadingView, null));
  }
  if (error) {
    return null;
  }
  if (data && hasBookableSpecialOffers(data)) {
    return __jsx(MainContainer, {
      testID: 'specialOfferBanner'
    }, __jsx(TitleText, null, Localize('special.offers.title')), __jsx(ListItem, {
      title: Localize('special.offers.viewAll'),
      subtitle: Localize('special.offers.miss'),
      onPress: handlePress,
      border: true,
      homePage: true,
      image: __jsx(StarIcon, {
        name: "star",
        size: 25
      })
    }));
  }
  return null;
};
var MainContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-top: 20px;\n"])));
var TitleText = themed(Title2)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.title);
});
var StarIcon = themed(Icon)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.homeScreen.icon);
});
export default SpecialOffersBanner;