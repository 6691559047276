import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
import React from "react";
var __jsx = React.createElement;
import Moment from 'react-moment';
import { Pressable, View } from 'react-native';
import Localize from '~/common/Localize';
import BranchTime from '~/components/BranchTime';
import { Action1, Body3, Container, Hero, SubTitle2, Theme, themed } from '~/styles/Theme';
import { isServiceReBookable
// TODO: this should be removed, and this bit of info part of the view model!
} from '../AppointmentHistoryViewModel';
import GroupBookingBanner from './GroupBookingBanner';
export default function PastAppointment(props) {
  return __jsx(ItemContainer, {
    style: {
      flex: 1
    },
    homePage: props.homePage
  }, __jsx(TimeContainer, null, __jsx(Day, {
    homePage: props.homePage,
    testID: 'pastAppointmentDayDate'
  }, __jsx(Moment, {
    format: 'DD',
    parse: 'YYYY-MM-DD'
  }, props.appointment.date)), __jsx(Month, {
    homePage: props.homePage,
    testID: 'pastAppointmentMonthDate'
  }, __jsx(Moment, {
    format: 'MMM',
    parse: 'YYYY-MM-DD'
  }, props.appointment.date))), __jsx(ServiceDetails, {
    appointment: props.appointment,
    homePage: props.homePage
  }), isServiceReBookable(props.appointment) && __jsx(Pressable, {
    style: {
      justifyContent: 'center',
      paddingBottom: 10,
      paddingRight: 5
    },
    onPress: props.onPress,
    testID: "rebookButton"
  }, __jsx(ReBookButton, {
    homePage: props.homePage
  }, __jsx(ReBookText, {
    homePage: props.homePage
  }, Localize('appointment.rebook')))));
}
var ServiceDetails = function ServiceDetails(props) {
  var numberOfClients = props.appointment.groupBookingNumberOfClients;
  var details = props.appointment.serviceItems.map(function (item, index) {
    var uniqueKey = "".concat(item.time, "-").concat(item.service.id, "-").concat(index);
    return __jsx(View, {
      key: uniqueKey,
      style: {
        flex: 1,
        paddingBottom: 5
      }
    }, __jsx(CancelledAppointment, {
      item: item
    }), __jsx(SubTitleText, {
      homePage: props.homePage
    }, item.serviceName), __jsx(View, {
      style: {
        flexDirection: 'row',
        paddingTop: 3
      }
    }, __jsx(InfoText, {
      homePage: props.homePage
    }, __jsx(BranchTime, null, item.time)), __jsx(InfoText, {
      homePage: props.homePage
    }, " ", Localize('global.with'), " "), __jsx(InfoText, {
      homePage: props.homePage
    }, item.staffName)));
  });
  return __jsx(ServiceContainer, {
    style: {
      flex: 1
    }
  }, details, numberOfClients && numberOfClients > 1 && __jsx(GroupBookingBanner, null));
};
var CancelledAppointment = function CancelledAppointment(props) {
  return props.item.cancelled ? __jsx(CancelledContainer, null, __jsx(CancelledText, null, Localize('appointment.cancelled'))) : null;
};
var ItemContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-direction: row\n  backgroundColor: white\n  padding-top: 15px\n  padding-bottom: 5px\n  padding-left: 20px\n  padding-right: 20px\n  borderRadius: 8px\n  margin: 7px 0px\n  elevation: 2\n  shadow-color: gray\n  shadow-offset: 2px 2px\n  shadow-opacity: 0.2\n  shadow-radius: 4px\n  min-height: 75px\n  ", "\n\n"])), function (props) {
  return props.homePage && "background-color: ".concat(Theme(props).colors.homeScreen.card);
});
var TimeContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: column\n  align-items: center\n  margin-right: 20px\n"])));
var ServiceContainer = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex-direction: column\n  padding-bottom: 5px\n"])));
var CancelledContainer = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100px\n  background-color: #f9e1e4\n  alignItems: center\n  border-radius: 5px\n"])));
var ReBookButton = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.homePage === true && "\n    width: 70px\n    height: 25px\n    borderRadius: 30px\n    justifyContent: center\n    alignItems: center\n    color: ".concat(Theme(props).colors.homeScreen.iconText, "\n    backgroundColor: ").concat(Theme(props).colors.main, "\n");
});
var ReBookText = themed(SubTitle2)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.info);
}, function (props) {
  return props.homePage && "color: ".concat(Theme(props).colors.homeScreen.iconText);
});
var SubTitleText = themed(SubTitle2)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.homePage && "color: ".concat(Theme(props).colors.homeScreen.subTitle);
});
var CancelledText = themed(Action1)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", "\n  padding-left: 5px;\n  padding-top: 2px;\n  padding-right: 5px;\n  padding-bottom: 2px;\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.error);
});
var Day = themed(Hero)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.homePage && "color: ".concat(Theme(props).colors.homeScreen.icon);
});
var Month = themed(SubTitle2)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.homePage && "color: ".concat(Theme(props).colors.homeScreen.icon);
});
var InfoText = themed(Body3)(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.homePage && "color: ".concat(Theme(props).colors.homeScreen.subTitle);
});